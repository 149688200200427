<template>
    <footer>
        <div class="BgFooter">
            <v-row class="footer">
                <v-col class="d-flex align-center justify-start policyWidth">
                    <img class="logoMobile " src="../../public/ressources/images/global/whiteLogo.svg" title="logo de wx3data" alt="logo de wx3data" />
                    <ul class="policytexte">
                        <li><a @click="openCookie()" v-html="$t('footer.cookie')"></a></li>
                        <li><router-link to="/PolitiqueDeConfidentialiter"  v-html="$t('footer.policy')"></router-link></li>
                    </ul>
                </v-col>
                <v-col class="d-none d-md-flex align-center justify-center">
                    <img class="logo " src="../../public/ressources/images/global/whiteLogo.svg" title="logo de wx3data" alt="logo de wx3data" />
                </v-col>
                <v-col class="d-none d-md-flex align-center justify-end">
                    <a href="https://www.facebook.com/profile.php?id=100057288773309" target="_blank"><img class="logoFacebook" src="../../public/ressources/images/global/facebook.svg" title="logo Facebook de wx3data" alt="logo Facebook de wx3data" /></a>
                </v-col>
            </v-row>
        </div>
    </footer>
</template>
<style scoped>
    .BgFooter { position: relative;  background: linear-gradient(transparent 10%, #BB5229 90%); padding: 20px; }
    .footer { height: 100px; width: 100%; }
    .v-row { height: 100px; }
    .v-col { height: 100px; }
    .logoFacebook { height: 30px; width: 30px; }
    .logoFacebook:hover { content:url('../../public/ressources/images/global/facebookBlue.svg'); cursor: pointer; }
    .logo { height: 70%; width: auto;  }
    .logoMobile { display: none; }
    .copyright { font-size: clamp(0.5rem, 2vw,1rem); font-weight: 100;  }
    .policytexte {  gap: 20px; list-style: none; padding: 0; margin: 0; display: flex; justify-content: center; }
    .policytexte a { text-decoration: none; color:rgb(var(--tierce)); font-size: clamp(1rem, 2vw,1rem); padding: 0px 0; position: relative; font-weight: 100; }
    .policytexte li { position: relative; }
    .policytexte a:hover { color: rgb(var(--tierce)); }
    .policytexte a::after { content: ''; position: absolute; left: 0%; bottom: 0; height: 1px; width: 0; background-color: rgb(var(--tierce)); transition: width 0.4s ease, left 0.4s ease; }
    .policytexte a:hover::after { width: 100%; left: 0; }
    a { cursor: pointer; }
    @media handheld, only screen and (max-width: 600px) {
        .policyWidth { min-width: 100%;}
        .logoMobile { display: block; position: absolute; height: 50px; right: 0; bottom: 0;}
    }
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
import translations from '../../public/ressources/i18n/global.json';
export default {
    name: 'Footer',
    data: () => ({
    }),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        //this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods: {
        switchLanguage: function () {
         this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en';     
        },
        currentLang() {
            return this.$i18n.locale;
        },
        openCookie: function(){ $App.event.$emit('openCookie'); }
    },
    component: {}
}
</script>
