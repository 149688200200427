window.$App = {
    name: "WX3data",
    tenant: "WX3",
    url: {
        // root: "https:./app.html",
        // login: "https:./login.html",
        api: "https://abstracxion.wx3data.com/",
        root: "http:./app.html",
        login: "http:./login.html",
        //api: "http://localhost:17548/",
    },
    getRangeHeader: function (response) {
        // see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Range
        //Content-Range: <unit> <range-start>-<range-end>/<size>
        //Content-Range: <unit> <range-start>-<range-end>/*
        //Range: <unit>=<range-start>-
        //Range: <unit>=<range-start>-<range-e nd>

        if (response.status != 206) { return null; }

        let range = 'items=0-';
        if (response) {
            let contentRange = response.headers.get('Content-Range');
            if (contentRange) {
                let r = contentRange.split(' ');
                if (r.length > 0) {
                    let r2 = r[1].split('/');
                    if (r2.length > 0) {
                        let r3 = r2[0].split('-');
                        if (r3.length > 0) {
                            let first = parseInt(r3[1]) + 1;
                            let total = parseInt(r2[1]);
                            if (first != total) {
                                range = 'items=' + first + '-';
                            }
                        }
                    }
                }
            }
        }

        return range;
    }
}; 