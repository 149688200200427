import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Policy from '../views/Policy.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/PolitiqueDeConfidentialiter',
    name: 'Policy',
    component: Policy
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      setTimeout(() => {
        if (el.length) {
          const offset = 150; // Décalage en pixels, ajustez selon vos besoins
          const elPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset;
          const scrollToPosition = elPosition - offset;
          window.scrollTo({
            top: scrollToPosition,
            behavior: "smooth"
          });
        }
      }, 5);
      
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
