<template>
  <v-app>
    <v-main>
      <Cookie/>
      <cHeader/>
      <router-view/>
      <cFooter/>
    </v-main>
  </v-app>

</template>
<script>
  import Cookie from '../src/components/cookie.vue';
export default {
  name: 'App',
  data:()=>({
    authorizations:{
        Essential: true,
        Performance: false,
        Personalization: false,
        Advertising: false,
    },
  }),
  mounted() {},
  methods:{
    VisibilityTools: function(){
      let getAuthorizations = JSON.parse(localStorage.getItem("authorizationCookies"));
      if(getAuthorizations) { this.authorizations = JSON.parse(localStorage.getItem("authorizationCookies")); }       
    
    }
  },
  components: { Cookie, }
}
</script>
